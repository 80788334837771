import React from 'react';
import styles from './PageLayout.module.scss';
import Header from '../components/Header';
import { Footer } from '../components/Footer';
import { HashRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
	MainStore,
	MainStoreConsumer,
	MainStoreProvider,
} from '../stores/MainStore';
import ProcessBar from '../components/ProcessBar';
import { Routing } from '../components/Routing';

@observer
export default class PageLayout extends React.Component {
	private store = new MainStore();

	public render(): React.ReactElement {
		return (
			<div className={styles.pageLayout}>
				<HashRouter>
					<MainStoreProvider value={this.store}>
						<Header />
						<div className={styles.contentBox}>
							<MainStoreConsumer>
								{(store) => {
									const steps = store.processBar.activeSteps;
									return steps && steps.length > 0 && <ProcessBar />;
								}}
							</MainStoreConsumer>
							<section className={styles.pageContent}>
								<Routing />
							</section>
							<Footer />
						</div>
					</MainStoreProvider>
				</HashRouter>
			</div>
		);
	}
}
