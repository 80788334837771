import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/react';
import PageLayout from './layouts';
import { registerSVGIcons } from './utils/Icons';
initializeIcons();
registerSVGIcons();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(<PageLayout />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
