import * as React from 'react';
import { IChoiceFieldInputProps } from './IChoiceFieldInputProps';
import { MainStoreConsumer } from '../../../stores/MainStore';
import { ChoiceGroup } from '@fluentui/react';
import styles from './ChoiceFieldInput.module.scss';
import { T } from '../../../stores/TranslationStore';

export default class ChoiceFieldInput extends React.Component<IChoiceFieldInputProps> {
	public render(): JSX.Element {
		const { internalName, label, options, disabled, isExternalJson } =
			this.props;
		return (
			<MainStoreConsumer>
				{(store) => {
					const input = store.inputs.inputValues[internalName];
					const requestType = store.inputs.inputValues['RequestType'].value;

					return (
						<>
							<ChoiceGroup
								label={store.translation.t(label)}
								className={styles.choiceInputs}
								options={options.map((option) => {
									return {
										key: option,
										text: isExternalJson
											? store.inputs.getRecipientType(
													option,
													store.translation.language,
													requestType
											  )
											: store.translation.t(option),
									};
								})}
								selectedKey={input.value}
								onChange={(ev, option) =>
									option && store.inputs.setInputValue(internalName, option.key)
								}
								required={input.required}
								disabled={disabled}
							/>
							{input.errorMessage && (
								<span className={styles.isError}>
									<T>{input.errorMessage}</T>
								</span>
							)}
						</>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
