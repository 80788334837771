import * as React from 'react';
import { IDropdownFieldInputProps } from './IDropdownFieldInputProps';
import { MainStoreConsumer } from '../../../stores/MainStore';
import { Dropdown, css } from '@fluentui/react';
import styles from './DropdownFieldInput.module.scss';
import { Language } from '../../../interfaces/Language';

export default class DropdownFieldInput extends React.Component<IDropdownFieldInputProps> {
	public render(): JSX.Element {
		const { label, internalName, disabled } = this.props;
		return (
			<MainStoreConsumer>
				{(store) => {
					const input = store.inputs.inputValues[internalName];
					return (
						<Dropdown
							options={store.countries
								.map((c) => {
									return {
										key: store.translation.t(c.name, Language.en),
										text: store.translation.t(c.name),
									};
								})
								.sort((a, b) => {
									if (a.text < b.text) return -1;
									if (a.text > b.text) return 1;
									return 0;
								})}
							label={store.translation.t(label)}
							className={css(
								styles.dropdownInput,
								disabled && styles.isDisabled
							)}
							required={input.required}
							onChange={(event, option) =>
								option &&
								store.inputs.setInputValue(internalName, option.key.toString())
							}
							selectedKey={input.value}
							disabled={disabled}
						/>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
