import * as React from 'react';
import styles from './FormPage.module.scss';
import { MainStoreConsumer } from '../../../stores/MainStore';
import { RequestType } from '../../../interfaces/RequestType';
import { T } from '../../../stores/TranslationStore';
import { css } from '@fluentui/react';
import AsideImage from '../../elements/AsideImage';
import FormPageInputs from '../../elements/formInputs/FormPageInputs';
import ButtonBar from '../../ButtonBar';

export default class FormPage extends React.Component {
	public render(): JSX.Element {
		return (
			<div className={css(styles.content, styles.isSubPage)}>
				<MainStoreConsumer>
					{(store) => {
						const requestType = store.inputs.inputValues['RequestType'].value;

						const formHeadline =
							requestType === RequestType.sponsoring
								? 'Step2-1_Page_Headline'
								: 'Step2-2_Page_Headline';
						const formDescription =
							requestType === RequestType.sponsoring
								? 'Step2-1_Page_Infotext'
								: 'Step2-2_Page_Infotext';
						const formSectionHeadline =
							requestType === RequestType.sponsoring
								? 'Step2-1_Section_01_Headline'
								: 'Step2-2_Section_01_Headline';
						const formSectionDescription =
							requestType === RequestType.sponsoring
								? 'Step2-1_Section_01_Infotext'
								: 'Step2-2_Section_01_Infotext';

						return (
							<>
								<div>
									<h2>
										<T>{formHeadline}</T>
									</h2>
									<MainStoreConsumer>
										{(store) => (
											<p
												dangerouslySetInnerHTML={{
													__html: store.translation.t(formDescription),
												}}
											></p>
										)}
									</MainStoreConsumer>
									<h3>
										<T>{formSectionHeadline}</T>
									</h3>
									<MainStoreConsumer>
										{(store) => (
											<p
												dangerouslySetInnerHTML={{
													__html: store.translation.t(formSectionDescription),
												}}
											></p>
										)}
									</MainStoreConsumer>
									<FormPageInputs />
									<ButtonBar
										prevUrl="/request"
										nextUrl={`/request/type/${store.inputs.inputValues[
											'RequestType'
										]?.value.toLowerCase()}/data`}
										prevBtnLabel="Go_back_button"
										nextBtnLabel="Step3_Button"
										activeStep={3}
										disabled={!store.inputs.areInputsFilled}
									/>
								</div>
								<AsideImage url={'Step2_image_url'} />
							</>
						);
					}}
				</MainStoreConsumer>
			</div>
		);
	}
}
