import * as React from 'react';
import { MainStoreConsumer } from '../../../../stores/MainStore';
import { T } from '../../../../stores/TranslationStore';
import styles from '../../Elements.module.scss';
import TextFieldInput from '../../../inputs/TextFieldInput';
import ChoiceFieldInput from '../../../inputs/ChoiceFieldInput';
import DropdownFieldInput from '../../../inputs/DropdownFieldInput';
import { IContactDataInputsProps } from './IContactDataInputsProps';

export default class ContactDataInputs extends React.Component<IContactDataInputsProps> {
	public render(): JSX.Element {
		const { disabled, isReview } = this.props;
		return (
			<>
				<h3>
					{isReview ? (
						<T>Step5_Section_03_Headline</T>
					) : (
						<T>Step3_Section_01_Headline</T>
					)}
				</h3>

				<MainStoreConsumer>
					{(store) => {
						const infotext = isReview
							? 'Step5_Section_03_Infotext'
							: 'Step3_Section_01_Infotext';
						return (
							<>
								<p
									dangerouslySetInnerHTML={{
										__html: store.translation.t(infotext),
									}}
								></p>
								<div className={styles.fields}>
									<TextFieldInput
										label="Step3_Section_01_ContactFirstName"
										internalName="ContactFirstName"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_01_ContactLastName"
										internalName="ContactLastName"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_01_ContactPhone"
										internalName="ContactPhone"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_01_ContactEmail"
										internalName="ContactEmail"
										disabled={disabled}
									/>
								</div>
							</>
						);
					}}
				</MainStoreConsumer>
				<h3>
					{isReview ? (
						<T>Step5_Section_03_1_Headline</T>
					) : (
						<T>Step3_Section_02_Headline</T>
					)}
				</h3>
				<MainStoreConsumer>
					{(store) => {
						const infotext = isReview
							? 'Step5_Section_03_1_Infotext'
							: 'Step3_Section_02_Infotext';
						const requestType = store.inputs.inputValues['RequestType'].value;
						const options =
							requestType === 'Sponsoring'
								? store.inputs.recipientSponsoringTypes
								: store.inputs.recipientDonationTypes;
						return (
							<>
								<p
									dangerouslySetInnerHTML={{
										__html: store.translation.t(infotext),
									}}
								></p>
								<div className={styles.fields}>
									{isReview ? (
										<TextFieldInput
											label="Step3_Section_02_RecipientType"
											internalName="RecipientType"
											disabled={disabled}
										/>
									) : (
										<ChoiceFieldInput
											label="Step3_Section_02_RecipientType"
											internalName="RecipientType"
											options={options.map((t) => t.key)}
											disabled={disabled}
											isExternalJson={true}
										/>
									)}
									<TextFieldInput
										label="Step3_Section_02_RecipientOrgName"
										internalName="RecipientOrgName"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_02_RecipientTaxID"
										internalName="RecipientTaxID"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_02_RecipientStreetAndNumber"
										internalName="RecipientStreetAndNumber"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_02_RecipientPostcode"
										internalName="RecipientPostcode"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_02_RecipientCity"
										internalName="RecipientCity"
										disabled={disabled}
									/>
									<DropdownFieldInput
										label="Step3_Section_02_RecipientCountry"
										internalName="RecipientCountry"
										disabled={disabled}
									/>

									<TextFieldInput
										label="Step3_Section_02_RecipientWebsite"
										internalName="RecipientWebsite"
										disabled={disabled}
									/>
								</div>
							</>
						);
					}}
				</MainStoreConsumer>
				<h3>
					{isReview ? (
						<T>Step5_Section_03_2_Headline</T>
					) : (
						<T>Step3_Section_03_Headline</T>
					)}
				</h3>
				<MainStoreConsumer>
					{(store) => {
						const infotext = isReview
							? 'Step5_Section_03_2_Infotext'
							: 'Step3_Section_03_Infotext';
						return (
							<>
								<p
									dangerouslySetInnerHTML={{
										__html: store.translation.t(infotext),
									}}
								></p>
								<div className={styles.fields}>
									<TextFieldInput
										label="Step3_Section_03_BankIBAN"
										internalName="BankIBAN"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_03_BankBIC"
										internalName="BankBIC"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_03_BankName"
										internalName="BankName"
										disabled={disabled}
									/>
									<TextFieldInput
										label="Step3_Section_03_BankAccountOwner"
										internalName="BankAccountOwner"
										disabled={disabled}
									/>
									<DropdownFieldInput
										label="Step3_Section_03_BankCountry"
										internalName="BankCountry"
										disabled={disabled}
									/>
								</div>
							</>
						);
					}}
				</MainStoreConsumer>
			</>
		);
	}
}
