import * as React from 'react';
import styles from './ButtonBar.module.scss';
import { css } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { TheButton } from '../TheButton';
import { MainStoreConsumer } from '../../stores/MainStore';
import { IButtonBarProps } from './IButtonBarProps';
import { T } from '../../stores/TranslationStore';

export default class ButtonBar extends React.Component<IButtonBarProps> {
	public render(): React.ReactElement {
		const {
			nextUrl,
			prevUrl,
			activeStep,
			nextBtnLabel,
			prevBtnLabel,
			requestButtons,
			disabled,
		} = this.props;
		return (
			<MainStoreConsumer>
				{(store) => (
					<div
						className={css(
							styles.buttons,
							requestButtons && styles.requestButtons
						)}
					>
						{prevUrl && prevBtnLabel && (
							<Link to={prevUrl}>
								<TheButton
									title="Go back"
									secondary
									iconProps={{ iconName: 'ChevronLeftSmall' }}
									onClick={() => store.processBar.removeActiveStep()}
								>
									<T>{prevBtnLabel}</T>
								</TheButton>
							</Link>
						)}
						<Link to={nextUrl}>
							<TheButton
								title="Confirm"
								iconProps={{ iconName: 'ChevronRightSmall' }}
								onClick={() => {
									store.processBar.setActiveStep(activeStep);
									store.inputs.validateHalfForm(activeStep);
									if (activeStep === 6) {
										store.submitForm();
									}
								}}
								disabled={disabled}
							>
								<T>{nextBtnLabel}</T>
							</TheButton>
						</Link>
					</div>
				)}
			</MainStoreConsumer>
		);
	}
}
