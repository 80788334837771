import { makeAutoObservable } from 'mobx';
import inputStore from './InputsStore';
import captchaStore from './CaptchaStore';

export class ProcessBarStore {
	public activeSteps: number[] = [];

	public setActiveStep = (step: number): void => {
		this.activeSteps.push(step);

		if (step === 0) this.resetValues();
	};

	constructor() {
		makeAutoObservable<ProcessBarStore>(this);
	}

	public removeActiveStep = (): void => {
		this.activeSteps.pop();
		this.scrollToTop();
	};

	private resetValues = (): void => {
		captchaStore.setCaptcha('');
		inputStore.resetInputValues();
	};

	private scrollToTop = (): void => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
}

const processBarStore = new ProcessBarStore();
export default processBarStore;
