import * as React from 'react';
import { T } from '../../../stores/TranslationStore';
import { MainStoreConsumer } from '../../../stores/MainStore';
import styles from './ReviewPage.module.scss';
import { Spinner, SpinnerSize, css } from '@fluentui/react';
import AsideImage from '../../elements/AsideImage';

export default class ReviewPage extends React.Component {
	public render(): JSX.Element {
		return (
			<div className={css(styles.content, styles.isSubPage)}>
				<MainStoreConsumer>
					{(store) => {
						return (
							<>
								<div className={store.isSubmitLoading ? styles.isLoading : ''}>
									{store.isSubmitLoading ? (
										<div>
											<h3>
												<T>Step6_Loading_Headline</T>
											</h3>
											<p
												dangerouslySetInnerHTML={{
													__html: store.translation.t(
														'Step6_Loading_Description'
													),
												}}
											></p>

											<Spinner size={SpinnerSize.large} />
										</div>
									) : store.isError ? (
										<>
											<h2>
												<T>Step6_Error_Headline</T>
											</h2>
											<p
												dangerouslySetInnerHTML={{
													__html: store.translation.t(
														'Step6_Error_Description'
													),
												}}
											></p>
										</>
									) : (
										<>
											<h2>
												<T>Step6_Page_Headline</T>
											</h2>
											<p
												dangerouslySetInnerHTML={{
													__html: store.translation.t('Step6_Page_Infotext'),
												}}
											></p>

											<h4>
												<T>Step6_Section_01_Headline</T>
											</h4>
											<div className={styles.requestId}>
												<span>{store.prevRequestId}</span>
											</div>

											<h3>
												<T>Step6_Section_02_Headline</T>
											</h3>
											<p
												dangerouslySetInnerHTML={{
													__html: store.translation.t(
														'Step6_Section_02_Infotext'
													),
												}}
											></p>
										</>
									)}
								</div>
								<AsideImage url="Step6_image_url" />
							</>
						);
					}}
				</MainStoreConsumer>
			</div>
		);
	}
}
