import * as React from 'react';
import Dropzone from 'react-dropzone';
import { TheButton } from '../../TheButton';
import { T } from '../../../stores/TranslationStore';
import { IAttachmentInputProps } from './IAttachmentInputProps';
import { MainStoreConsumer } from '../../../stores/MainStore';
import styles from './AttachmentInput.module.scss';
import { Modal } from '@fluentui/react';

export default class AttachmentInput extends React.Component<IAttachmentInputProps> {
	public render(): JSX.Element {
		return (
			<MainStoreConsumer>
				{(store) => {
					return (
						<div>
							<Dropzone
								onDrop={(files, rejections, event) =>
									store.attachments.onDrop(files, rejections, event)
								}
							>
								{({ getRootProps, getInputProps }) => (
									<section className={styles.attachmentButton}>
										<div {...getRootProps()}>
											<input {...getInputProps()} />
											<TheButton title="Add" iconProps={{ iconName: 'Add' }}>
												<T>Step4_Select_Button</T>
											</TheButton>
										</div>
									</section>
								)}
							</Dropzone>

							<Modal
								isOpen={store.attachments.modalOpen}
								onDismiss={store.attachments.onClose}
								isBlocking={false}
								containerClassName={styles.modalContainer}
							>
								<div className={styles.header}>
									<h5 className={styles.heading}>
										{store.translation.t('Step4_Att_failed_upload')}
									</h5>
									<TheButton
										title="Close"
										isIcon
										iconProps={{ iconName: 'Cancel' }}
										ariaLabel="Close popup modal"
										onClick={store.attachments.onClose}
									/>
								</div>
								<div className={styles.modalContent}>
									{store.translation.t(store.attachments.message)}
								</div>
								<div className={styles.modalFooter}>
									<TheButton title="Close" onClick={store.attachments.onClose}>
										{store.translation.t('Homepage_Captcha_Close')}
									</TheButton>
								</div>
							</Modal>
						</div>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
