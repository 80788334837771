import { ITranslation } from '../interfaces/ITranslation';
import { Language } from '../interfaces/Language';
import { MainStoreConsumer } from './MainStore';
import { action, makeAutoObservable, observable } from 'mobx';

export class TranslationStore {
	public language: Language =
		(localStorage.getItem('dns-language') as Language) || Language.en;

	private translations: ITranslation[] | null = null;

	constructor() {
		makeAutoObservable<TranslationStore, 'translations' | 'setTranslations'>(
			this,
			{
				translations: observable,
				setTranslations: action,
			}
		);
		this.getTranslations();
	}

	public t = (key: string, language?: Language): string => {
		const translation = this.translations?.find((trans) => trans.key === key);
		if (!translation) return key;
		return (
			((language || this.language) === Language.en
				? translation.en
				: translation.de) ||
			translation.en ||
			translation.de
		);
	};

	public refreshCookieSettingsOneTrust(
		language?: Language,
		attempt: number = 1
	) {
		if (window.OneTrust && window.OneTrust.changeLanguage) {
			window.OneTrust.changeLanguage(language || this.language);
		} else if (attempt <= 15) {
			setTimeout(() => {
				this.refreshCookieSettingsOneTrust(language, attempt++);
			}, 100);
		}
	}

	public saveLanguage = (lang: Language): void => {
		this.language = lang;
		localStorage.setItem('dns-language', lang);
		this.refreshCookieSettingsOneTrust(this.language);
	};

	public getTranslations = async (): Promise<void> => {
		try {
			const response = await fetch('translations.json');
			const jsonData: ITranslation[] = await response.json();

			this.setTranslations(jsonData);
		} catch (error) {
			console.error(`[getTranslations]: ${error}`);
		}
	};

	public setTranslations = (translations: ITranslation[]): void => {
		this.translations = translations;
	};
}

export const T: React.FC<{ children: string; language?: Language }> = ({
	children,
	language,
}) => (
	<MainStoreConsumer>
		{(store) => store.translation.t(children, language)}
	</MainStoreConsumer>
);

const translationStore = new TranslationStore();
export default translationStore;
