import { Language } from '../interfaces/Language';
import { registerIcons } from '@fluentui/react/lib/Styling';

// function parseInlineStyle(style: string): React.CSSProperties {
// 	const template = document.createElement('template');
// 	template.setAttribute('style', style);
// 	return Object.entries(template.style)
// 		.filter(([key]) => !/^[0-9]+$/.test(key))
// 		.filter(([, value]) => Boolean(value))
// 		.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
// }

export function registerSVGIcons(): void {
	const pinEn = (
		<svg
			width="61"
			height="58"
			viewBox="0 0 61 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="61" height="58" fill="none" />
			<path
				d="M2 44.6362V4.5C2 4.22386 2.22386 4 2.5 4H57.5C57.7761 4 58 4.22386 58 4.5V24.5681V44.6363C58 44.9124 57.7761 45.1363 57.5 45.1363H24.6305C24.5299 45.1363 24.4318 45.1665 24.3487 45.2232L8.3412 56.1395C7.94196 56.4118 7.4271 56.0155 7.58804 55.5599L11.0344 45.8028C11.1493 45.4774 10.908 45.1363 10.563 45.1363H2.5C2.22386 45.1363 2 44.9124 2 44.6362Z"
				stroke="#002967"
				strokeWidth="2"
			/>
			<path
				d="M11.0909 36V12.7273H24.5909V14.6364H13.2159V23.3977H23.875V25.3068H13.2159V34.0909H24.8182V36H11.0909ZM48.2159 12.7273V36H46.1477L32.3977 16.5114H32.2159V36H30.0909V12.7273H32.1477L45.9432 32.2386H46.125V12.7273H48.2159Z"
				fill="#002967"
			/>
		</svg>
	);
	const pinDe = (
		<svg
			width="61"
			height="58"
			viewBox="0 0 61 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="61" height="58" fill="none" />
			<path
				d="M2 44.6362V4.5C2 4.22386 2.22386 4 2.5 4H57.5C57.7761 4 58 4.22386 58 4.5V24.5681V44.6363C58 44.9124 57.7761 45.1363 57.5 45.1363H24.6305C24.5299 45.1363 24.4318 45.1665 24.3487 45.2232L8.3412 56.1395C7.94196 56.4118 7.4271 56.0155 7.58804 55.5599L11.0344 45.8028C11.1493 45.4774 10.908 45.1363 10.563 45.1363H2.5C2.22386 45.1363 2 44.9124 2 44.6362Z"
				stroke="#002967"
				strokeWidth="2"
			/>
			<path
				d="M17.9091 36H11.0909V12.7273H18.3182C20.5379 12.7273 22.4356 13.1894 24.0114 14.1136C25.5947 15.0379 26.8068 16.3636 27.6477 18.0909C28.4886 19.8182 28.9091 21.8902 28.9091 24.3068C28.9091 26.7462 28.4773 28.8409 27.6136 30.5909C26.7576 32.3333 25.5076 33.6705 23.8636 34.6023C22.2273 35.5341 20.2424 36 17.9091 36ZM13.2159 34.0909H17.7841C19.7841 34.0909 21.4583 33.697 22.8068 32.9091C24.1553 32.1212 25.1667 30.9962 25.8409 29.5341C26.5152 28.072 26.8523 26.3295 26.8523 24.3068C26.8447 22.2992 26.5114 20.572 25.8523 19.125C25.2008 17.678 24.2311 16.5682 22.9432 15.7955C21.6629 15.0227 20.0795 14.6364 18.1932 14.6364H13.2159V34.0909ZM34.0597 36V12.7273H47.5597V14.6364H36.1847V23.3977H46.8438V25.3068H36.1847V34.0909H47.7869V36H34.0597Z"
				fill="#002967"
			/>
		</svg>
	);

	registerIcons({
		icons: {
			[Language.en]: pinEn,
			[Language.de]: pinDe,
		},
	});
}
