import * as React from 'react';
import { MainStoreConsumer } from '../../../stores/MainStore';
import styles from './ReviewData.module.scss';
import { T } from '../../../stores/TranslationStore';
import ReviewDataInputs from '../../elements/formInputs/ReviewDataInputs';
import { css } from '@fluentui/react';
import AsideImage from '../../elements/AsideImage';
import ButtonBar from '../../ButtonBar';

export default class ReviewData extends React.Component {
	public render(): JSX.Element {
		return (
			<div className={css(styles.content, styles.isSubPage)}>
				<MainStoreConsumer>
					{(store) => {
						return (
							<>
								<div className={styles.sections}>
									<h2>
										<T>Step5_Page_Headline</T>
									</h2>
									<MainStoreConsumer>
										{(store) => (
											<p
												dangerouslySetInnerHTML={{
													__html: store.translation.t('Step5_Page_Infotext'),
												}}
											></p>
										)}
									</MainStoreConsumer>

									<ReviewDataInputs />

									<ButtonBar
										nextUrl={`/request/type/${store.inputs.inputValues[
											'RequestType'
										].value.toLowerCase()}/data/attachments/review/summary`}
										prevUrl={`/request/type/${store.inputs.inputValues[
											'RequestType'
										].value.toLowerCase()}/data/attachments`}
										nextBtnLabel="Step5_Button"
										prevBtnLabel="Go_back_button"
										activeStep={6}
									/>
								</div>
								<AsideImage url="Step5_image_url" />
							</>
						);
					}}
				</MainStoreConsumer>
			</div>
		);
	}
}
