import * as React from 'react';
import { MainStoreConsumer } from '../../stores/MainStore';
import styles from './ProcessBar.module.scss';
import { css } from '@fluentui/react';

export default class ProcessBar extends React.Component {
	public render(): JSX.Element {
		return (
			<MainStoreConsumer>
				{(store) => {
					const steps = store.processBar.activeSteps;
					return (
						<div className={styles.processBar}>
							<div
								className={css(
									styles.processBarStep,
									steps.findIndex((elem) => elem === 1) !== -1 && styles.active
								)}
							>
								1
							</div>
							<div
								className={css(
									styles.processBarStep,
									steps.findIndex((elem) => elem === 2) !== -1 && styles.active
								)}
							>
								2
							</div>
							<div
								className={css(
									styles.processBarStep,
									steps.findIndex((elem) => elem === 3) !== -1 && styles.active
								)}
							>
								3
							</div>
							<div
								className={css(
									styles.processBarStep,
									steps.findIndex((elem) => elem === 4) !== -1 && styles.active
								)}
							>
								4
							</div>
							<div
								className={css(
									styles.processBarStep,
									steps.findIndex((elem) => elem === 5) !== -1 && styles.active
								)}
							>
								5
							</div>
							<div
								className={css(
									styles.processBarStep,
									steps.findIndex((elem) => elem === 6) !== -1 && styles.active
								)}
							>
								6
							</div>
						</div>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
