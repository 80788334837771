import * as React from 'react';
import { IInfoPageContent } from '../../../../interfaces/IInfoPageContent';
import { MainStoreConsumer } from '../../../../stores/MainStore';
import { T } from '../../../../stores/TranslationStore';

export interface IDataProtectionPageProps {
	content: IInfoPageContent;
}

export default class DataProtectionPage extends React.Component<
	IDataProtectionPageProps,
	{}
> {
	render() {
		const { content } = this.props;
		return (
			<MainStoreConsumer>
				{(store) => {
					return (
						<>
							<p
								dangerouslySetInnerHTML={{
									__html: store.translation.t(content.content),
								}}
							></p>
							<button
								id="ot-sdk-btn"
								className="ot-sdk-show-settings"
								onClick={() => store.handleOneTrustModal()}
							>
								<T>Data_protection_settingsButton</T>
							</button>
							{content.content1 && (
								<p
									dangerouslySetInnerHTML={{
										__html: store.translation.t(content.content1),
									}}
								></p>
							)}
						</>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
