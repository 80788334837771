import { makeAutoObservable } from 'mobx';
import { IAttachmentsConfig } from '../interfaces/IAttachmentsConfig';
import { DropEvent, FileRejection } from 'react-dropzone';

export class AttachmentsStore {
	public attachments: File[] = [];
	public attachmentsConfig: IAttachmentsConfig;
	public isSubmitted: boolean = false;

	public message: string = '';
	public modalOpen: boolean = false;

	public onDrop = <T extends File>(
		acceptedFiles: T[],
		fileRejections: FileRejection[],
		event: DropEvent
	): void => {
		const newFileSize = acceptedFiles[0].size / 1024 ** 2;

		const totalCurrentFilesSize = this.getTotalFilesSize();

		const totalCurrentFilesSizeBytes =
			totalCurrentFilesSize / 1024 ** 2 + newFileSize;

		if (totalCurrentFilesSizeBytes > this.attachmentsConfig.totalFilesSize) {
			this.message = 'Step4_Att_error_all_too_large';
			this.modalOpen = true;
			return;
		}

		this.setAttachments(acceptedFiles);
	};

	public onClose = (): void => {
		this.modalOpen = false;
		this.message = '';
	};

	public onDelete = (name: string): void => {
		const filteredAttachments = this.attachments.filter(
			(ca) => ca.name !== name
		);
		this.setCurrentAttachments(filteredAttachments);
	};

	public getAttachmentsData = (): FormData => {
		const attData: FormData = new FormData();
		if (this.attachments.length) {
			for (let att of this.attachments) {
				attData.append(att.name, att);
			}
		}

		return attData;
	};

	public clearAttachments = (): void => {
		this.attachments = [];
	};

	constructor() {
		makeAutoObservable<AttachmentsStore>(this);

		this.getAttachmentsConfig();
	}

	private getAttachmentsConfig = async (): Promise<void> => {
		try {
			const response = await fetch('attachmentsConfig.json');
			const jsonData: IAttachmentsConfig = await response.json();
			this.setAttachmentsConfig(jsonData);
		} catch (error) {
			console.error(`[getAttachmentsConfig]: ${error}`);
		}
	};

	private setAttachmentsConfig = (data: IAttachmentsConfig): void => {
		this.attachmentsConfig = data;
	};

	private getTotalFilesSize = (): number => {
		return this.attachments.reduce((prev, curr) => {
			return prev + curr.size;
		}, 0);
	};

	private setAttachments = (files: File[]): void => {
		const currentFiles = this.attachments;
		const allowedFormats = this.attachmentsConfig.allowedFormats;

		for (const file of files) {
			const fileSize = file.size / 1024 ** 2;

			const fileFormat = file.name.split('.').slice(-1)[0];

			if (allowedFormats.indexOf(fileFormat) === -1) {
				this.message = 'Step4_Att_error_file_format';
				this.modalOpen = true;
				return;
			}

			if (this.attachmentsConfig.singleFileSize < fileSize) {
				this.message = 'Step4_Att_error_file_too_large';
				this.modalOpen = true;
				return;
			} else {
				const fileName = file.name;
				if (currentFiles.some((item) => item.name === fileName)) {
					this.message = 'Step4_Att_error_file_name';
					this.modalOpen = true;
					return;
				} else {
					currentFiles.push(file);
					this.setCurrentAttachments(currentFiles);
				}
			}
		}
	};

	private setCurrentAttachments = (files: File[]): void => {
		this.attachments = files;
	};
}
