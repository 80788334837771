import * as React from 'react';
import { T } from '../../../stores/TranslationStore';
import styles from './Request.module.scss';
import { RequestTypes } from '../../elements/RequestTypes';
import { MainStoreConsumer } from '../../../stores/MainStore';
import AsideImage from '../../elements/AsideImage';
import { css } from '@fluentui/react';
import ButtonBar from '../../ButtonBar';

export default class Request extends React.Component {
	public render(): React.ReactElement {
		return (
			<div className={css(styles.content, styles.isSubPage)}>
				<MainStoreConsumer>
					{(store) => {
						const requestType = store.inputs.inputValues['RequestType'].value;
						return (
							<>
								<div>
									<h2>
										<T>Step1_Page_Headline</T>
									</h2>
									<p
										dangerouslySetInnerHTML={{
											__html: store.translation.t('Step1_Page_Infotext'),
										}}
									></p>
									<RequestTypes />
									{requestType !== '' && (
										<ButtonBar
											nextUrl={`/request/type/${store.inputs.inputValues[
												'RequestType'
											]?.value.toLowerCase()}`}
											nextBtnLabel="Step1_Button"
											activeStep={2}
											requestButtons
										/>
									)}
								</div>
								<AsideImage url={'Step1_image_url'} />
							</>
						);
					}}
				</MainStoreConsumer>
			</div>
		);
	}
}
