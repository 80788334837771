import { MainStoreConsumer } from '../../stores/MainStore';
import { T } from '../../stores/TranslationStore';
import styles from './Footer.module.scss';

export const Footer = (): JSX.Element => (
	<footer>
		<div className={styles.footerWrapper}>
			<MainStoreConsumer>
				{(store) => (
					<ul>
						<li>
							<a
								href={store.translation.t('Footer_imprint_url')}
								target={store.translation.t('Footer_imprint_target')}
							>
								<T>Footer_imprint</T>
							</a>
						</li>
						<li>|</li>
						<li>
							<a
								href={store.translation.t('Footer_legal_inf_url')}
								target={store.translation.t('Footer_legal_inf_target')}
							>
								<T>Footer_legal_inf</T>
							</a>
						</li>
						<li>|</li>
						<li>
							<a
								href={store.translation.t('Footer_data_protection_url')}
								target={store.translation.t('Footer_data_protection_target')}
							>
								<T>Footer_data_protection</T>
							</a>
						</li>
					</ul>
				)}
			</MainStoreConsumer>
		</div>
	</footer>
);
