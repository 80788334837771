import { FontIcon, Link } from '@fluentui/react';
import * as React from 'react';
import styles from './NotFound.module.scss';

export class NotFound extends React.Component {
	public render(): React.ReactElement {
		return (
			<div className={styles.notFound}>
				<div className={styles.notFoundIcon}>
					<FontIcon aria-label="Not found" iconName="ErrorBadge" />
				</div>
				<h2>Sorry, we could not find this page.</h2>
				<Link to="/">Home</Link>
			</div>
		);
	}
}
