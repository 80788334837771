import * as React from 'react';
import { MainStoreConsumer } from '../../../stores/MainStore';
import styles from './ContactData.module.scss';
import { T } from '../../../stores/TranslationStore';
import ContactDataInputs from '../../elements/formInputs/ContactDataInputs';
import { css } from '@fluentui/react';
import AsideImage from '../../elements/AsideImage';
import ButtonBar from '../../ButtonBar';
import { RequestType } from '../../../interfaces/RequestType';

export default class ContactData extends React.Component {
	public render(): JSX.Element {
		return (
			<div className={css(styles.content, styles.isSubPage)}>
				<MainStoreConsumer>
					{(store) => {
						const requestType = store.inputs.inputValues['RequestType'].value;
						return (
							<>
								<div className={styles.sections}>
									<h2>
										{requestType === RequestType.donation ? (
											<T>Step3_Page_Headline_Donation</T>
										) : (
											<T>Step3_Page_Headline_Sponsoring</T>
										)}
									</h2>
									<p
										dangerouslySetInnerHTML={{
											__html: store.translation.t('Step3_Page_Infotext'),
										}}
									></p>

									<ContactDataInputs />

									<ButtonBar
										nextUrl={`/request/type/${store.inputs.inputValues[
											'RequestType'
										].value.toLowerCase()}/data/attachments`}
										prevUrl={`/request/type/${store.inputs.inputValues[
											'RequestType'
										].value.toLowerCase()}`}
										nextBtnLabel="Step3_Button"
										prevBtnLabel="Go_back_button"
										activeStep={4}
										disabled={!store.inputs.areDataInputsFilled}
									/>
								</div>
								<AsideImage url="Step3_image_url" />
							</>
						);
					}}
				</MainStoreConsumer>
			</div>
		);
	}
}
