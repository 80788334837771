import * as React from 'react';
import Captcha from '../../Captcha';
import { TheButton } from '../../TheButton';
import styles from './Home.module.scss';
import { T } from '../../../stores/TranslationStore';
import { MainStoreConsumer } from '../../../stores/MainStore';
import { observer } from 'mobx-react';
import { Dialog } from '@fluentui/react';
import AsideImage from '../../elements/AsideImage';
import { Navigate } from 'react-router-dom';

@observer
export class Home extends React.Component {
	public render(): React.ReactElement {
		return (
			<MainStoreConsumer>
				{(store) => {
					store.setIsHomepage();
					return (
						<>
							<div className={styles.content}>
								<div>
									<h1>
										<T>Homepage_Page_Headline</T>
									</h1>
									<p
										className={styles.description}
										dangerouslySetInnerHTML={{
											__html: store.translation.t('Homepage_Page_Infotext'),
										}}
									></p>
									{!store.captcha.isCaptchaValid && (
										<>
											<h2>
												<T>Homepage_Section_01_Headline</T>
											</h2>
											<p
												className={styles.description}
												dangerouslySetInnerHTML={{
													__html: store.translation.t(
														'Homepage_Section_01_Infotext'
													),
												}}
											></p>
										</>
									)}
								</div>

								{store.captcha.isCaptchaValid ? (
									<Navigate to={'/request'} />
								) : (
									<Captcha />
								)}

								<Dialog
									hidden={!store.captcha.captchaError}
									onDismiss={() => store.captcha.setCaptchaError(false)}
									modalProps={{ className: styles.dialog }}
								>
									<div className={styles.dialogInner}>
										<h2>
											<T>Homepage_Captcha_Error</T>
										</h2>
										<div className={styles.dialogButtons}>
											<TheButton
												title="Close"
												onClick={() => store.captcha.setCaptchaError(false)}
											>
												<T>Homepage_Captcha_Close</T>
											</TheButton>
										</div>
									</div>
								</Dialog>
							</div>
							<AsideImage url={'Homepage_image_url'} />
						</>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
