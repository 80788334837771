import React from 'react';
import {
	loadCaptchaEnginge,
	LoadCanvasTemplate,
} from '@vinhpd/react-simple-captcha';
import { TextField } from '@fluentui/react';
import styles from './Captcha.module.scss';
import { TheButton } from '../TheButton';
import { T } from '../../stores/TranslationStore';
import { MainStoreConsumer } from '../../stores/MainStore';
import { observer } from 'mobx-react';

@observer
export default class Captcha extends React.Component {
	public async componentDidMount(): Promise<void> {
		loadCaptchaEnginge(4);
	}

	public render(): React.ReactElement {
		return (
			<MainStoreConsumer>
				{(store) => {
					return (
						<div className={styles.captcha}>
							<LoadCanvasTemplate />
							<TextField
								placeholder={store.translation.t(
									'Homepage_Enter_Captcha_Value'
								)}
								className={styles.captchaField}
								value={store.captcha.captchaValue}
								onChange={(ev, value) => {
									store.captcha.setCaptcha(value ? value : '');
								}}
							/>
							<TheButton
								title="Check"
								onClick={store.captcha.checkCaptcha}
								style={{ marginLeft: '1rem' }}
							>
								<T>Check</T>
							</TheButton>
						</div>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
