import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../pages/NotFound';
import Request from '../pages/Request';
import { Home } from '../pages/Home';
import FormPage from '../pages/FormPage';
import ContactData from '../pages/ContactData';
import ReviewPage from '../pages/ReviewPage';
import InfoPage from '../pages/InfoPage';
import { InfoPageType } from '../../interfaces/InfoPageType';
import ReviewData from '../pages/ReviewData';
import AttachmentsPage from '../pages/AttachmentsPage';

export const Routing = (): React.ReactElement => (
	<Routes>
		<Route path="/" element={<Home />} />
		<Route path="/request/*" element={<Request />} />
		<Route path="/request/type/:type" element={<FormPage />} />
		<Route path="/request/type/:type/*" element={<FormPage />} />
		<Route path="/request/type/:type/data" element={<ContactData />} />
		<Route
			path="/request/type/:type/data/attachments"
			element={<AttachmentsPage />}
		/>
		<Route
			path="request/type/:type/data/attachments/review"
			element={<ReviewData />}
		/>
		<Route
			path="/request/type/:type/data/attachments/review/summary"
			element={<ReviewPage />}
		/>
		<Route path="*" element={<NotFound />} />
		<Route path="/imprint" element={<InfoPage type={InfoPageType.imprint} />} />
		<Route
			path="/legal-information"
			element={<InfoPage type={InfoPageType.legal} />}
		/>
		<Route
			path="/data-protection"
			element={<InfoPage type={InfoPageType.protection} />}
		/>
	</Routes>
);
