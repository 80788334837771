import { FC, PropsWithChildren } from 'react';
import styles from './TheButton.module.scss';
import { ITheButtonProps } from './ITheButtonProps';
import {
	DefaultButton,
	IButtonProps,
	IconButton,
	PrimaryButton,
	css,
} from '@fluentui/react';

export const TheButton: FC<PropsWithChildren<ITheButtonProps>> = ({
	title,
	isIcon,
	iconProps,
	disabled,
	children,
	onClick,
	style,
	secondary,
	ariaLabel,
}) => {
	const props: IButtonProps = {
		title,
		iconProps,
		onClick,
		disabled,
		style,
		ariaLabel,
	};
	if (isIcon) {
		return <IconButton {...props} />;
	}
	return (
		<>
			{secondary ? (
				<DefaultButton
					{...props}
					className={css(styles.button, styles.secondary)}
				>
					{children}
				</DefaultButton>
			) : (
				<PrimaryButton
					{...props}
					className={css(styles.button, styles.primary)}
				>
					{children}
				</PrimaryButton>
			)}
		</>
	);
};
