import { makeAutoObservable } from 'mobx';
import { InputValue } from '../interfaces/InputValue';
import { IFieldValue } from '../interfaces/IFieldValue';
import { RequestInputs } from '../interfaces/RequestInputs';
import { RequestType } from '../interfaces/RequestType';
import { RequestBody } from '../interfaces/RequestBody';
import { Language } from '../interfaces/Language';
import translationStore from './TranslationStore';
import { ITranslation } from '../interfaces/ITranslation';

class InputsStore {
	public inputValues: InputValue = {};
	public requestInputs: RequestInputs = {};
	public isSponsoringSelected: boolean = false;
	public isDonationSelected: boolean = false;
	public isSubmitted: boolean = false;
	public isError: boolean = false;
	public requestLangauge: string = '';
	public recipientDonationTypes: ITranslation[] = [];
	public recipientSponsoringTypes: ITranslation[] = [];

	public isInitialized: boolean = false;

	public isSubmit: boolean = false;
	public areInputsFilled: boolean = false;
	public areDataInputsFilled: boolean = false;

	public resetInputValues = (): void => {
		this.setInitialFields();
	};

	public setRequestLanguage = (value: Language): void => {
		const language: string = value === Language.en ? 'English' : 'German';
		this.requestLangauge = language;
		this.inputValues['RequestLanguage'].value = language;
	};

	public getHiddenValue(internalName: string, hiddenProps?: boolean): boolean {
		const requestScopeValue = this.inputValues['RequestScope'];
		if (!requestScopeValue) return false;
		switch (internalName) {
			case 'RequestGoods':
				return (
					requestScopeValue.toString().includes('Goods') ||
					requestScopeValue.toString().includes('Both')
				);
			case 'RequestMoney':
				return (
					requestScopeValue.toString().includes('Money') ||
					requestScopeValue.toString().includes('Both')
				);
			default:
				return hiddenProps ? true : false;
		}
	}

	constructor() {
		makeAutoObservable<InputsStore>(this);

		this.setInitialFields();

		this.getRecipientDonationTypes();
		this.getRecipientSponsoringTypes();
	}

	public validateInput = (value: string): string => {
		const { isSubmit } = this;
		if (isSubmit) {
			if (!value || value.length === 0) {
				return 'Input cannot be empty.';
			}
		}
		return '';
	};

	public setInputValue = (
		internalName: string,
		value: string | undefined,
		pattern?: RegExp
	): void => {
		const input = this.inputValues[internalName];
		if (!value) {
			input.value = '';
			input.required
				? (input.errorMessage = 'Invalid_field_message')
				: (input.errorMessage = '');
			this.validateHalfForm(input.step);

			return;
		}

		input.value = value;

		if (pattern) {
			this.validateFieldValue(value, internalName);
			return;
		}

		if (input.errorMessage) {
			input.errorMessage = '';
		}

		if (internalName === 'RequestType') {
			this.inputValues['RequestScope'].value = 'Money';
			this.setRequired(true, value);

			this.setIsActive(value);
		}

		if (internalName === 'RequestScope') {
			if (value.includes('Money')) {
				this.inputValues['RequestMoney'].required = true;
				this.inputValues['RequestGoods'].required = false;
			}
			if (value.includes('Goods')) {
				this.inputValues['RequestGoods'].required = true;
				this.inputValues['RequestMoney'].required = false;
			}
			if (value.includes('Both')) {
				this.inputValues['RequestMoney'].required = true;
				this.inputValues['RequestGoods'].required = true;
			}
		}

		if (input.step === 2 || input.step === 3) {
			this.validateHalfForm(input.step);
		}
	};

	private setIsActive = (value: string): void => {
		const requestType = this.inputValues['RequestType'].value;

		if (requestType === RequestType.sponsoring) {
			this.isSponsoringSelected = true;
			this.isDonationSelected = false;
		} else {
			this.isSponsoringSelected = false;
			this.isDonationSelected = true;
		}
	};

	private setRequired = (isRequired: boolean, value: string): void => {
		if (value === 'Sponsoring') {
			this.inputValues['RequestSponsoringCompensation'].required = isRequired;
		}
		this.inputValues['RequestMoney'].required = isRequired;
	};

	public getRequestBody = (): RequestBody => {
		const values = this.inputValues;
		const requestBody: RequestBody = {};

		Object.entries(values).forEach(([key, value]) => {
			if (key === 'RequestMoney') {
				requestBody[key] = this.transformCurrency(value.value);
			} else if (key === 'RequestScope') {
				requestBody[key] = translationStore.t(value.value, Language.en);
			} else if (key === 'RecipientType') {
				requestBody[key] =
					values['RequestType'].value === 'Donation'
						? this.getRecipientDonationType(value.value, Language.en)
						: this.getRecipientSponsoringType(value.value, Language.en);
			} else {
				requestBody[key] = value.value;
			}
		});

		return requestBody;
	};

	public setRequestType = (key: string): void => {
		this.inputValues['RequestType'].value = key;
		this.setInitialFields();
	};

	private transformCurrency = (value: string): string => {
		return value.replace(/[\..]/g, '').replace(/[\,]/g, '.');
	};

	public validateHalfForm = (step: number): void => {
		const inputs = Object.values(this.inputValues).filter(
			(input) => input.step === step
		);

		const requiredInputs = inputs.filter((input) => input.required);

		const everyFilled = requiredInputs.every(
			(input) => input.value !== '' && !input.errorMessage
		);

		if (step === 2) {
			this.areInputsFilled = everyFilled;
		}
		if (step === 3) {
			this.areDataInputsFilled = everyFilled;
		}
	};

	public setIsError = (value: boolean): void => {
		this.isError = value;
	};

	public setIsSubmitted = (value: boolean): void => {
		this.isSubmitted = value;
	};

	public validateFieldValue = (value: string, internalName: string) => {
		const input = this.inputValues[internalName];
		const pattern = input.pattern;

		const test = pattern && pattern.test(value);

		if (!test && value) {
			input.errorMessage = 'Incorrect_field_message';
		} else if (!value && input.required) {
			input.errorMessage = 'Invalid_field_message';
		} else {
			input.errorMessage = '';
			input.value = value;
		}
		this.validateHalfForm(input.step);
	};

	public getRecipientType = (
		option: string,
		lang: Language,
		requestType: string
	): string => {
		return requestType === 'Sponsoring'
			? this.getRecipientSponsoringType(option, lang)
			: this.getRecipientDonationType(option, lang);
	};

	private getRecipientSponsoringType = (
		option: string,
		lang: Language
	): string => {
		const recipientTypes = this.recipientSponsoringTypes;
		return recipientTypes
			.filter((type) => type.key === option)
			.map((op) => op[lang])[0];
	};

	private getRecipientDonationType = (
		option: string,
		lang: Language
	): string => {
		const recipientTypes = this.recipientDonationTypes;
		return recipientTypes
			.filter((type) => type.key === option)
			.map((op) => op[lang])[0];
	};

	private getRecipientDonationTypes = async (): Promise<void> => {
		try {
			const response = await fetch('recipientDonationTypes.json');
			const jsonData: ITranslation[] = await response.json();
			this.setRecipientDonationTypes(jsonData);
		} catch (error) {
			console.error(`[getRecipientDonationTypes]: ${error}`);
		}
	};

	private getRecipientSponsoringTypes = async (): Promise<void> => {
		try {
			const response = await fetch('recipientSponsoringTypes.json');
			const jsonData: ITranslation[] = await response.json();
			this.setRecipientSponsoringTypes(jsonData);
		} catch (error) {
			console.error(`[getRecipientSponsoringTypes]: ${error}`);
		}
	};

	private setRecipientDonationTypes = (data: ITranslation[]): void => {
		this.recipientDonationTypes = data;
	};

	private setRecipientSponsoringTypes = (data: ITranslation[]): void => {
		this.recipientSponsoringTypes = data;
	};

	public setInitialFields = (): void => {
		const values: { [key: string]: IFieldValue } = {
			RequestId: { value: this.renderRequestId(), step: 1 },
			RequestLanguage: { value: this.requestLangauge, step: 1 },
			RequestType: { value: '', step: 1, required: true },
			RequestPurpose: { value: '', step: 2, required: true },
			RequestSponsoringCompensation: { value: '', step: 2, required: false },
			RequestScope: { value: '', step: 2, required: false },
			RequestGoods: { value: '', step: 2, required: false },
			RequestMoney: {
				value: '',
				step: 2,
				required: false,
				pattern: new RegExp(/^[1-9][0-9]*$/),
				placeholder: 'Step2_Money_Placeholder',
			},
			RecipientType: { value: '', step: 3, required: true },
			RecipientOrgName: { value: '', step: 3, required: true },
			RecipientTaxID: { value: '', step: 3, required: true },
			RecipientStreetAndNumber: { value: '', step: 3, required: true },
			RecipientPostcode: { value: '', step: 3, required: true },
			RecipientCity: { value: '', step: 3, required: true },
			RecipientCountry: { value: 'Germany', step: 3, required: true },
			RecipientWebsite: { value: '', step: 3, required: false },
			ContactFirstName: { value: '', step: 3, required: true },
			ContactLastName: { value: '', step: 3, required: true },
			ContactEmail: {
				value: '',
				step: 3,
				required: true,
				pattern: new RegExp(
					/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
				),
			},
			ContactPhone: {
				value: '',
				step: 3,
				required: false,
				pattern: new RegExp(/^(\+?(\d+(\s?|-?))+)$/),
			},
			BankIBAN: { value: '', step: 3, required: true, maxLength: 34 },
			BankBIC: { value: '', step: 3, required: false },
			BankAccountOwner: { value: '', step: 3, required: true },
			BankName: { value: '', step: 3, required: true },
			BankCountry: { value: 'Germany', step: 3, required: true },
		};
		this.inputValues = values;
	};

	private renderRequestId = (): string => {
		const date = new Date();
		const year = date.getFullYear();
		const monthValue = date.getMonth() + 1;
		const month =
			monthValue.toString().length === 1 ? `0${monthValue}` : monthValue;
		const day =
			date.getDate().toString().length === 1
				? `0${date.getDate()}`
				: date.getDate();
		const randomNumbers = Math.floor(100000 + Math.random() * 900000);

		return `${year}${month}${day}-${randomNumbers}`;
	};
}

const inputStore = new InputsStore();
export default inputStore;
