import * as React from 'react';
import { T } from '../../../../stores/TranslationStore';
import { MainStoreConsumer } from '../../../../stores/MainStore';
import AttachmentInput from '../../../inputs/AttachmentInput';
import AttachmentsList from '../../../pages/AttachmentsPage/AttachmentsList';

export default class AttachmentsControl extends React.Component {
	public render(): JSX.Element {
		return (
			<>
				<h3>
					<T>Step4_Section_01_Headline</T>
				</h3>
				<MainStoreConsumer>
					{(store) => (
						<>
							<p
								dangerouslySetInnerHTML={{
									__html: store.translation.t('Step4_Section_01_Infotext'),
								}}
							></p>
							<AttachmentInput files={store.attachments.attachments} />
						</>
					)}
				</MainStoreConsumer>
				<AttachmentsList />
			</>
		);
	}
}
