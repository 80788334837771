import * as React from 'react';
import { IInfoPageProps } from './IInfoPageProps';
import { IInfoPageContent } from '../../../interfaces/IInfoPageContent';
import { InfoPageType } from '../../../interfaces/InfoPageType';
import { T } from '../../../stores/TranslationStore';
import { MainStoreConsumer } from '../../../stores/MainStore';
import styles from './InfoPage.module.scss';
import { TheButton } from '../../TheButton';
import { Link } from 'react-router-dom';
import DataProtectionPage from './DataProtectionPage/DataProtectionPage';

export default class InfoPage extends React.Component<IInfoPageProps> {
	public componentDidMount(): void {
		if (window.OneTrust && window.OneTrust.initializeCookiePolicyHtml) {
			window.OneTrust.initializeCookiePolicyHtml();
		}
	}

	public render(): React.ReactElement {
		const { type } = this.props;

		const content: IInfoPageContent = this.getContent(type);

		return (
			<div className={styles.infoPageContent}>
				<h1>
					<T>{content.headline}</T>
				</h1>
				<MainStoreConsumer>
					{(store) => {
						return (
							<>
								{type === InfoPageType.protection ? (
									<DataProtectionPage content={content} />
								) : (
									<p
										dangerouslySetInnerHTML={{
											__html: store.translation.t(content.content),
										}}
									></p>
								)}
							</>
						);
					}}
				</MainStoreConsumer>
				<div className={styles.buttons}>
					<MainStoreConsumer>
						{(store) => (
							<Link to={`/`}>
								<TheButton
									title="Go back"
									secondary
									iconProps={{ iconName: 'ChevronLeftSmall' }}
								>
									<T>Go_back_button</T>
								</TheButton>
							</Link>
						)}
					</MainStoreConsumer>
				</div>
			</div>
		);
	}

	private getContent = (type: string): IInfoPageContent => {
		switch (type) {
			case InfoPageType.imprint:
				return {
					headline: 'Imprint_headline',
					content: 'Imprint_content',
				};
			case InfoPageType.legal:
				return {
					headline: 'Legal_inf_headline',
					content: 'Legal_inf_content',
				};
			case InfoPageType.protection:
				return {
					headline: 'Data_protection_headline',
					content: 'Data_protection_content',
					content1: 'Data_protection_content1',
				};
			default:
				return { headline: '', content: '' };
		}
	};
}
