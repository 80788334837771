import React from 'react';
import styles from './Header.module.scss';
import logoImage from '../../common/images/logo.svg';
import { TheButton } from '../TheButton';
import { MainStoreConsumer } from '../../stores/MainStore';
import { observer } from 'mobx-react';
import { Language } from '../../interfaces/Language';

@observer
export default class Header extends React.Component {
	public render(): JSX.Element {
		return (
			<header className={styles.header}>
				<div className={styles.wrapper}>
					<h1 className={styles.logo}>
						<a href="/">
							<img src={logoImage} alt="Fresenius Donation & Sponsoring" />
						</a>
					</h1>
					<MainStoreConsumer>
						{(store) => {
							return (
								<nav>
									{store.translation.language === Language.en ? (
										<TheButton
											title="Germany"
											isIcon
											iconProps={{ iconName: Language.de }}
											secondary
											onClick={() => store?.setLanguage(Language.de)}
										></TheButton>
									) : (
										<TheButton
											title="English"
											isIcon
											iconProps={{ iconName: Language.en }}
											secondary
											onClick={() => store?.setLanguage(Language.en)}
										></TheButton>
									)}
								</nav>
							);
						}}
					</MainStoreConsumer>
				</div>
			</header>
		);
	}
}
