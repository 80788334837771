import * as React from 'react';
import { IAttachmentsListProps } from './IAttachmentsListProps';
import { T } from '../../../../stores/TranslationStore';
import { MainStoreConsumer } from '../../../../stores/MainStore';
import styles from './AttachmentsList.module.scss';
import { TheButton } from '../../../TheButton';

export default class AttachmentsList extends React.Component<IAttachmentsListProps> {
	public render(): JSX.Element {
		const { isReview } = this.props;
		return (
			<>
				<h3>
					{isReview ? (
						<T>Step5_Section_04_Headline</T>
					) : (
						<T>Step4_Section_02_Headline</T>
					)}
				</h3>
				<MainStoreConsumer>
					{(store) => {
						const infotext = isReview
							? 'Step5_Section_04_Infotext'
							: 'Step4_Section_02_Infotext';
						return (
							<>
								<p
									dangerouslySetInnerHTML={{
										__html: store.translation.t(infotext),
									}}
								></p>
								<div className={styles.attachmentsList}>
									{store.attachments.attachments.length === 0 ? (
										<div>
											<T>Step4_Att_no_files</T>
										</div>
									) : (
										store.attachments.attachments.map((a) => (
											<div key={a.name} className={styles.attachmentRow}>
												<span>{a.name}</span>
												{!isReview && (
													<TheButton
														title="Remove"
														isIcon
														iconProps={{ iconName: 'Delete' }}
														onClick={() => store.attachments.onDelete(a.name)}
													/>
												)}
											</div>
										))
									)}
								</div>
							</>
						);
					}}
				</MainStoreConsumer>
			</>
		);
	}
}
