import * as React from 'react';
import { MainStoreConsumer } from '../../../../stores/MainStore';
import styles from '../../Elements.module.scss';
import { RequestType } from '../../../../interfaces/RequestType';
import TextFieldInput from '../../../inputs/TextFieldInput';
import { IFormPageInputsProps } from './IFormPageInputsProps';

export default class FormPageInputs extends React.Component<IFormPageInputsProps> {
	public render(): JSX.Element {
		const { disabled } = this.props;
		return (
			<MainStoreConsumer>
				{(store) => {
					const requestType = store.inputs.inputValues['RequestType'].value;

					return (
						<div className={styles.fields}>
							<TextFieldInput
								label="Step2_Purpose"
								internalName="RequestPurpose"
								multiline={true}
								disabled={disabled}
							/>
							{requestType === RequestType.sponsoring && (
								<TextFieldInput
									label="Step2-1_Sponsoring_Compensation"
									internalName="RequestSponsoringCompensation"
									multiline={true}
									disabled={disabled}
								/>
							)}

							<TextFieldInput
								label="Step2_Money_Value"
								internalName="RequestMoney"
								disabled={disabled}
							/>
						</div>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
