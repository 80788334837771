import * as React from 'react';
import { IAsideImageProps } from './IAsideImageProps';
import { MainStoreConsumer } from '../../../stores/MainStore';

export default class AsideImage extends React.Component<IAsideImageProps> {
	public render(): JSX.Element {
		const { url } = this.props;
		return (
			<MainStoreConsumer>
				{(store) => (
					<aside>
						<div
							dangerouslySetInnerHTML={{
								__html: store.translation.t(url),
							}}
						></div>
					</aside>
				)}
			</MainStoreConsumer>
		);
	}
}
