import * as React from 'react';
import { ITextFieldInputProps } from './ITextFieldInputProps';
import { MainStoreConsumer } from '../../../stores/MainStore';
import { TextField } from '@fluentui/react';
import styles from './TextFieldInput.module.scss';

export default class TextFieldInput extends React.Component<ITextFieldInputProps> {
	public render(): JSX.Element {
		const { disabled, label, multiline, internalName } = this.props;

		return (
			<MainStoreConsumer>
				{(store) => {
					const input = store.inputs.inputValues[internalName];
					const requestType = store.inputs.inputValues['RequestType'].value;

					let value: string = '';
					if (internalName === 'RecipientType') {
						value = store.inputs.getRecipientType(
							input.value,
							store.translation.language,
							requestType
						);
					} else if (
						internalName === 'RequestType' ||
						internalName === 'RequestScope'
					) {
						value = store.translation.t(input.value);
					} else {
						value = input.value;
					}

					return (
						<TextField
							required={input.required}
							label={store.translation.t(label)}
							multiline={multiline}
							value={value}
							className={styles.textInput}
							maxLength={input.maxLength}
							disabled={disabled}
							onChange={(event, value) => {
								store.inputs.setInputValue(internalName, value, input.pattern);
							}}
							errorMessage={
								disabled
									? ''
									: input.errorMessage &&
									  store.translation.t(input.errorMessage)
							}
							placeholder={
								input.placeholder && store.translation.t(input.placeholder)
							}
						/>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
