import * as React from 'react';
import { MainStoreConsumer } from '../../../stores/MainStore';
import { ChoiceGroup } from '@fluentui/react';
import styles from './RequestTypes.module.scss';
import { T } from '../../../stores/TranslationStore';
import { observer } from 'mobx-react';

@observer
export class RequestTypes extends React.Component {
	render(): JSX.Element {
		return (
			<MainStoreConsumer>
				{(store) => {
					const activeClassName = store.inputs.isSponsoringSelected
						? styles.isSponsoringActive
						: store.inputs.isDonationSelected
						? styles.isDonationActive
						: '';
					return (
						<div className={styles.types}>
							<h3>
								<T>Step1_Section_01_Headline</T>
							</h3>
							<p>
								<T>Step1_Section_01_Infotext</T>
							</p>

							<ChoiceGroup
								defaultSelectedKey={
									store.inputs.inputValues['RequestType']?.value
								}
								className={activeClassName}
								options={[
									{
										key: 'Sponsoring',
										imageSize: { width: 32, height: 32 },
										text: store.translation.t('Step1_Request_Type_Sponsoring'),
									},
									{
										key: 'Donation',
										imageSize: { width: 32, height: 32 },
										text: store.translation.t('Step1_Request_Type_Donation'),
									},
								]}
								onChange={(ev, option) => {
									option &&
										store.inputs.setInputValue('RequestType', option.key);
								}}
							/>
							<div className={styles.typesBox}>
								<div>
									<h4>
										<T>Step1_Request_Type_Sponsoring</T>
									</h4>
									<p
										dangerouslySetInnerHTML={{
											__html: store.translation.t(
												'Step1_Sponsoring_Explaination'
											),
										}}
									></p>
								</div>
								<div>
									<h4>
										<T>Step1_Request_Type_Donation</T>
									</h4>
									<p
										dangerouslySetInnerHTML={{
											__html: store.translation.t(
												'Step1_Donation_Explaination'
											),
										}}
									></p>
								</div>
							</div>
						</div>
					);
				}}
			</MainStoreConsumer>
		);
	}
}
