import * as React from 'react';
import styles from './AttachmentsPage.module.scss';
import { css } from '@fluentui/react';
import { MainStoreConsumer } from '../../../stores/MainStore';
import { T } from '../../../stores/TranslationStore';
import ButtonBar from '../../ButtonBar';
import AsideImage from '../../elements/AsideImage';
import AttachmentsControl from '../../elements/formInputs/AttachmentsControl';

export default class AttachmentsPage extends React.Component {
	public render(): JSX.Element {
		return (
			<div className={css(styles.content, styles.isSubPage)}>
				<MainStoreConsumer>
					{(store) => (
						<>
							<div className={styles.sections}>
								<h2>
									<T>Step4_Page_Headline</T>
								</h2>
								<p
									dangerouslySetInnerHTML={{
										__html: store.translation.t('Step4_Page_Infotext'),
									}}
								></p>

								<AttachmentsControl />

								<ButtonBar
									nextUrl={`/request/type/${store.inputs.inputValues[
										'RequestType'
									].value.toLowerCase()}/data/attachments/review`}
									prevUrl={`/request/type/${store.inputs.inputValues[
										'RequestType'
									].value.toLowerCase()}/data`}
									nextBtnLabel="Step4_Button"
									prevBtnLabel="Go_back_button"
									activeStep={5}
								/>
							</div>
							<AsideImage url="Step4_image_url" />
						</>
					)}
				</MainStoreConsumer>
			</div>
		);
	}
}
