import { makeAutoObservable } from 'mobx';
import { validateCaptcha } from '@vinhpd/react-simple-captcha';
import inputStore from './InputsStore';
import processBarStore from './ProcessBarStore';

export class CaptchaStore {
	public captchaValue: string = '';
	public isCaptchaValid: boolean = false;
	public captchaError: boolean = false;

	constructor() {
		makeAutoObservable<CaptchaStore>(this);
	}

	public setCaptcha(value: string): void {
		this.captchaValue = value;
	}

	public checkCaptcha = (): void => {
		if (validateCaptcha(this.captchaValue)) {
			this.setCaptchaValid(true);
			inputStore.resetInputValues();
			processBarStore.setActiveStep(1);
		} else {
			this.setCaptchaError(true);
			this.setCaptchaValid(false);
		}
	};

	public setCaptchaValid(value: boolean): void {
		this.isCaptchaValid = value;
	}

	public setCaptchaError(value: boolean): void {
		this.captchaError = value;
	}
}

const captchaStore = new CaptchaStore();
export default captchaStore;
